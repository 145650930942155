import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { NotificationManager } from 'react-notifications'
import apiCall from 'src/backend/http'
import { formatDate } from 'src/backend/utility'
import { addNewRiskToItems, changeStep, UPDATE_ALL_RISKS } from 'src/slices/buyInsuranceSlice'
export const kycSubmitionAction = createAsyncThunk(
  'insurance/bk-tech/onBoardCustomer',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data, status } = await apiCall.post('insurance/bk-tech/onBoardCustomer', req)
      if (status === 200) {
        const {
          app: {
            newPolicy: { step },
          },
        } = getState()
        NotificationManager.success('KYC information has been submitted successfully!')
        dispatch(changeStep(step + 1))
        return data
      } else {
        NotificationManager.error('Failed to submit form')
        return rejectWithValue('Failed to submit form')
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message // Get the specific error message from the server
          : error.message // Fallback to the generic error message

      NotificationManager.error(errorMessage) // Display the specific error message
      return rejectWithValue(errorMessage)
    }
  },
)
export const getUpdatedPremiumCalculation = createAsyncThunk(
  'insurance/bk-tech/moto/premium/calculator',
  async (__, { rejectWithValue, dispatch, getState }) => {
    try {
      const {
        app: {
          newPolicy: { riskInfo, productCode, coverInfo, allRisks, step },
        },
      } = getState()
      let postReq = calcPostData(riskInfo, productCode, coverInfo)

      const { data, status } = await apiCall.post(
        'insurance/bk-tech/moto/premium/calculator',
        postReq,
      )
      if (status === 200) {
        await dispatch(addNewRiskToItems(updateRiskList(riskInfo, coverInfo, allRisks, data)))
        if (step > 0) {
          dispatch(changeStep(step - 1))
        }
      }
      return data
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  },
)

export const updateRiskList = (riskInfo, coverInfo, allRisks, calculatorResults) => {
  const newRisk = { ...calculatorResults, ...riskInfo, ...coverInfo }
  const existingRiskIndex = allRisks.findIndex(
    (risk) => risk?.data?.riskId === newRisk?.data?.riskId,
  )

  if (existingRiskIndex !== -1) {
    // Replace the existing risk
    return allRisks.map((risk, index) => (index === existingRiskIndex ? newRisk : risk))
  } else {
    // Create a new array with the new risk added
    return [...allRisks, newRisk]
  }
}

export const fireDomesticQuotation = createAsyncThunk(
  'insurance/fire/residential',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data, status } = await apiCall.post('insurance/bk-tech/fire/residential', req)

      if (status === 200) {
        NotificationManager.success(data?.message) // Show success notification
        const {
          app: {
            newPolicy: { step },
          },
        } = getState()

        dispatch(changeStep(step + 1))
        // Dispatch change step action on success
      } else {
        NotificationManager.warning(`Warning/Error Found: ${data?.message}`) // Show warning for non-200 status
      }
      return data
    } catch (ex) {
      NotificationManager.error(`Error: ${ex.response.data?.message}`) // Changed to error notification

      return rejectWithValue(ex.response.data)
    }
  },
)

export const makePayment = createAsyncThunk(
  'insurance/bk-tech/payQuotation',
  async (request, { rejectWithValue, dispatch }) => {
    //const {} = getState()

    try {
      const { data: resp, status } = await apiCall.post('insurance/bk-tech/payQuotation', request)

      if (status === 202 || status === 200) {
        //  if (resp?.data?.paymentStatus === 'SUCCESSFUL') {

        dispatch(makePayment.fulfilled(resp)) // Directly fulfilling with the response data
        NotificationManager.success(
          'Payment Request Is Sent if No Popup Check on the Pending Approval by Dialing *182*7*1*PIN#',
        )
        // dispatch(changeStep(2))
        //} else {
        //   startPollingPaymentStatus(resp.data.quotationNo, dispatch, rejectWithValue)
        // }
      } else {
        NotificationManager.error('Payment gateway is out of service')
        dispatch(makePayment.rejected(resp.data))
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'An Error Occurred'

      NotificationManager.error(`Error: ${errorMessage}`)
      return rejectWithValue({
        message: errorMessage || error.message || 'An unknown error occurred.',
      })
    }
  },
)

// Polling function remains the same with proper handling
/*
const startPollingPaymentStatus = (docNo, dispatch, rejectWithValue) => {
  const pollInterval = 5000
  let attempts = 0
  const maxAttempts = 12
  dispatch(makePayment.pending())
  const pollPaymentStatus = async () => {
    try {
      const { data: pollData, status: pollStatus } = await apiCall.get(
        `insurance/bk-tech/quotPaymentStatus?docNo=${docNo}`,
      )

      const {
        data: { paymentStatus },
      } = pollData
      if (pollStatus === 200) {
        if (paymentStatus === 'SUCCESSFUL') {
          dispatch(makePayment.fulfilled(pollData))
          NotificationManager.success('Payment completed successfully.')
          dispatch(changeStep(2))
        } else if (paymentStatus === 'PENDING' || paymentStatus === 'DEBIT_REQUEST_SENT') {
          attempts++
          if (attempts < maxAttempts) {
            dispatch(makePayment.fulfilled(pollData))
            setTimeout(pollPaymentStatus, pollInterval)
          } else {
            NotificationManager.info('Payment is taking longer than expected. Please check later.')
            dispatch(
              makePayment.rejected({
                message: 'Payment is taking longer than expected. Please check later.',
              }),
            )
          }
        } else {
          NotificationManager.error('Payment failed or encountered an issue.')
          dispatch(makePayment.rejected({ message: 'Payment failed.' }))
          return pollData
        }
      } else {
        NotificationManager.error('Error fetching payment status.:', pollStatus)
        dispatch(
          rejectWithValue({
            message: 'Online system is out of Service, try again later',
          }),
        )
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || 'An error occurred during polling.'
      NotificationManager.error(`Error: ${errorMessage}`)
      dispatch(makePayment.rejected({ message: errorMessage }))
    }
  }

  pollPaymentStatus()
}*/

const submitMotoQuotationAction = createAsyncThunk(
  'insurance/moto/calculate-premium',
  async (requestData, { rejectWithValue, dispatch, getState }) => {
    try {
      const url = 'insurance/bk-tech/motor/private/quotation'
      const { data, status } = await apiCall.post(url, requestData)
      if (status === 200) {
        NotificationManager.success(data?.message)
        const {
          app: {
            newPolicy: { step },
          },
        } = getState()
        dispatch(changeStep(step + 1))
      } else {
        NotificationManager.warning(`Warning/Error Found: ${data?.message}`) // Show warning for non-200 status
      }
      return data
    } catch (error) {
      NotificationManager.error(`Error: ${error.response.data?.message}`) // Changed to error notification
      return rejectWithValue(error.response.data) // Reject with error data
    }
  },
)

const submitFireQuotation = createAsyncThunk(
  'insurance/bk-tech/fire/quotation',
  async (postData, { rejectWithValue }) => {
    try {
      const { data, status } = await apiCall.post('insurance/bk-tech/fire/quotation', postData)
      if (status !== 200) {
        NotificationManager.error(data.message)
        return rejectWithValue(data.message)
      } else {
      }
      return data
    } catch (error) {
      return rejectWithValue(error.response.data || error.message)
    }
  },
)
const sendQuotationToEmail = createAsyncThunk(
  'notification/email/send-quotation',
  async (postReq, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.post('notification/email/send-quotation', postReq)
      return data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  },
)
const submitNoMotoQuotation = createAsyncThunk(
  'insurance/non-moto/calculate-premium',
  async (postData, toolKit) => {
    try {
      const {
        app: {
          bkProduct: { selectProduct },
        },
      } = toolKit.getState()
      let url =
        selectProduct.product_CODE === 'TRAVEL_INS'
          ? 'insurance/bk-tech/travel/quotation'
          : 'insurance/non-moto/calculate-premium'

      const { data } = await apiCall.post(url, postData)
      return data
    } catch (error) {
      return toolKit.rejectWithValue(error.response.data)
    }
  },
)
const submitPersonalAccidentQuotation = createAsyncThunk(
  'insurance/bk-tech/pa/quotation',
  async (postData, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.post('/insurance/bk-tech/pa/quotation', postData)
      return data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)
const getNidaInformation = createAsyncThunk(
  'nida/customer-info',
  async (nationalId, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.get(`insurance/bk-tech/nida?id=${nationalId}`)
      if (data.data !== null) {
        NotificationManager.success(
          `This National Id ${nationalId} is being found In Nida Successfully`,
        )
      } else {
        NotificationManager.error(`This NationalId:${nationalId} Not Found in the Nida`)
      }
      return data
    } catch (e) {
      NotificationManager.error(`This NationalId:${nationalId} Not Found in the Nida`)
      return rejectWithValue(e.response.data)
    }
  },
)
const getQuotationNoInfo = createAsyncThunk(
  'insurance/bk-tech/quotPaymentStatus',
  async (docNo, { rejectWithValue, dispatch }) => {
    try {
      const { data: response, status } = await apiCall.get(
        `insurance/bk-tech/quotPaymentStatus?docNo=${docNo}`,
      )

      if (status === 200 && response?.data) {
        const {
          data: { quotationNo, paymentStatus, payer_code, vehicles, policyStatus },
        } = response

        if (policyStatus === 'COMPLETED') {
          NotificationManager.success('Contract Found')
          // dispatch(changeStep(2)); // Uncomment if this step is required
        } else if (paymentStatus === 'SUCCESSFUL' && payer_code === quotationNo) {
          dispatch(changeStep(2))
          NotificationManager.success('Quotation found and payment completed')
        } else if (paymentStatus === 'PENDING') {
          dispatch(changeStep(1))
          dispatch(UPDATE_ALL_RISKS(vehicles))
          NotificationManager.info('Quotation found but payment is pending')
        } else {
          dispatch(changeStep(1))
          dispatch(UPDATE_ALL_RISKS(vehicles))
        }
      } else {
        dispatch(changeStep(0))
        NotificationManager.error(response?.message || 'Quotation not found')
      }

      return response
    } catch (e) {
      const errorMessage =
        e.response?.data?.message || 'An unexpected error occurred. Please try again later.'
      NotificationManager.error(errorMessage)
      return rejectWithValue(errorMessage)
    }
  },
)

// const getQuotationNoInfo = createAsyncThunk(
//   'insurance/bk-tech/quotPaymentStatus',
//   async (docNo, { rejectWithValue, dispatch, getState }) => {
//     try {
//       const { data: response, status } = await apiCall.get(
//         `insurance/bk-tech/quotPaymentStatus?docNo=${docNo}`,
//       )
//       const { message } = response
//       if (status == 200) {
//         const {
//           data: { quotationNo, paymentStatus, payer_code, vehicles, policyStatus },
//         } = response
//         if (
//           paymentStatus == 'SUCCESSFUL' &&
//           payer_code == quotationNo &&
//           policyStatus == 'PENDING'
//         ) {
//           dispatch(changeStep(2))
//           NotificationManager.success('Quotation found and payment completed')
//         } else if (paymentStatus == 'SUCCESSFUL' && policyStatus == 'COMPETE') {
//           dispatch(changeStep(4))
//           NotificationManager.success('Contract Found')
//         } else if (paymentStatus === 'PENDING') {
//           dispatch(changeStep(1))
//           dispatch(UPDATE_ALL_RISKS(vehicles))
//           NotificationManager.info('Quotation found but payment is pending, moving to Step 1')
//         } else {
//           dispatch(changeStep(1))
//           dispatch(UPDATE_ALL_RISKS(vehicles))
//         }
//       } else {
//         dispatch(changeStep(0))
//         NotificationManager.error(message || 'Quotation not found, returning to Step 0')
//       }
//       return response
//     } catch (e) {
//       return rejectWithValue(
//         e.response.data || 'An unexpected error occurred. Please try again later.',
//       )
//     }
//   },
// )

const getProvince = createAsyncThunk('insurance/location/provinces', async (rejectWithValue) => {
  try {
    const { data } = await apiCall.get('insurance/location/provinces')
    return data?.data
  } catch (error) {
    return rejectWithValue(error.response.response)
  }
})

const getDistrict = createAsyncThunk(
  'insurance/location/districts',
  async (provinceCode, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.get(
        `insurance/location/districts?provinceCode=${provinceCode}`,
      )
      return data?.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

const getSector = createAsyncThunk(
  'insurance/location/sectors?districtCode',
  async (districtCode, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.get(`insurance/location/sectors?districtCode=${districtCode}`)
      return data?.data
    } catch (error) {
      return rejectWithValue(error.response.response)
    }
  },
)
const getCell = createAsyncThunk(
  'insurance/location/cells?sectorCode',
  async (sectorCode, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.get(`insurance/location/cells?sectorCode=${sectorCode}`)

      return data?.data
    } catch (error) {
      return rejectWithValue(error.response.response)
    }
  },
)
const getVillages = createAsyncThunk(
  'insurance/location/villages?cellCode',
  async (cellCode, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.get(`insurance/location/villages?cellCode=${cellCode}`)
      return data?.data
    } catch (error) {
      return rejectWithValue(error.response.response)
    }
  },
)
const calcPostData = (riskInfo, productCode, coverInfo) => {
  return {
    policeDetails: {
      startDate: formatDate(coverInfo?.startDate),
      expiryDate: formatDate(coverInfo?.endDate),
      comesaStartDate: formatDate(coverInfo?.comesaStartDate),
      comesaEndDate: formatDate(coverInfo?.comesaEndDate),
      productCode: productCode,
      comesa: coverInfo?.comesa,
      covers: coverInfo?.covers,
      comesaCovers: coverInfo?.comesaCovers,
      cmedical: false,
      clientType: 'PRIVATE',
      sumInsuredPerOccupants: coverInfo?.sumInsuredPerOccupants,
      numberOfOccupants: coverInfo?.numberOfOccupants,
    },
    vehicleDetails: {
      plateNo: riskInfo?.plateNo,
      manufacturingYear: riskInfo?.yearOfMake,
      vehicleTypeId: riskInfo?.motoTypeItem?.motoTypeId,
      noOfSeats: riskInfo?.numberOfSeats,
      valueOfVehicle: 0,
    },
    source: 'WEB_PORTAL',
  }
}
const restSubmitionStatus = createAction('RESET_QUOTATION_SUBMISSION')
const showHideFirePreview = createAction('FIRE_PREVIEW')
const refleshNidaState = createAction('REFRESH_NIDA')
const keepItem = createAction('KEEP_SELECTED_ITEM')
const COVER_SELECTED = createAction('COVER_SELECTED')
const COMESA_COVER_SELECTED = createAction('COMESA_COVER_SELECTED')
const updatedCovers = createAction('UPDATED_COVERS')
const SELECTED_MOTO_TYPE = createAction('SELECTED_MOTO_TYPE')
export {
  COMESA_COVER_SELECTED,
  COVER_SELECTED,
  getCell,
  getDistrict,
  getNidaInformation,
  getProvince,
  getQuotationNoInfo,
  getSector,
  getVillages,
  keepItem,
  refleshNidaState,
  restSubmitionStatus,
  SELECTED_MOTO_TYPE,
  sendQuotationToEmail,
  showHideFirePreview,
  submitFireQuotation,
  submitMotoQuotationAction,
  submitNoMotoQuotation,
  submitPersonalAccidentQuotation,
  updatedCovers,
}
