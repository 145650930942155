import { createSlice } from '@reduxjs/toolkit'
import {
  fireDomesticQuotation,
  getCell,
  getDistrict,
  getProvince,
  getQuotationNoInfo,
  getSector,
  getUpdatedPremiumCalculation,
  getVillages,
  kycSubmitionAction,
  makePayment,
  submitMotoQuotationAction,
} from 'src/actions/quotation/quotation-action'
import { getMotoPrivateInsuranceContract } from 'src/actions/updateRiskList/updateRiskList'
import { getInsuranceContract } from 'src/actions/updateRiskList/updateRiskList'
const buyStateObj = {
  isRiskFormValid: false,
  step: 0,
  isCalculator: false,
  nida: false,
  risksToSubmit: [],
  payment: {},
  master: {},
  productCode: '',
  riskInfo: {},
  fleet: false,
  coverPeriod: {},
  coverInfo: {},
  policeHolder: {},
  calculatorResults: {},
  disableRiskDate: false,
  quotationUpdates: {},
  dwnldContract: false,
  premium: {},
  contract: {},
  currentRisk: {},
  allRisks: [],
  provinces: [],
  districts: [],
  sectors: [],
  cells: [],
  villages: [],
  refleshPayment: false,
  loading: false,
  error: false,
  message: false,
  success: false,
  triggerPH: false,
  newRiskData: [],
}

const BuyNewInsuranceInfo = createSlice({
  name: 'createNewPolice',
  initialState: buyStateObj,
  reducers: {
    newPremiumReset: () => {
      return buyStateObj
    },
    webSocketContractUpdates: (state, action) => {
      const data = action.payload
      if (!data) {
        console.error('Missing payload data')
        return state // Return the current state if data is missing
      }
      return {
        ...state,
        dwnldContract: data?.errorContract ? false : true,
        loading: false,
        success: data?.errorContract ? false : true,
        error: data?.errorContract ? true : false,
        contract: data,
        message: data?.message,
      }
    },
    webSocketPaymentStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        refleshPayment: false,
        premium: action.payload,
        message: 'Payment Completed Successfully Process with KYC Sharing',
      }
    },
    updateNidaState: (state, action) => {
      return { ...state, nida: action?.payload, loading: false }
    },
    UPDATE_ALL_RISKS: (state, action) => {
      return { ...state, allRisks: action.payload }
    },
    riskToSubmitAction: (state, action) => {
      return { ...state, risksToSubmit: action.payload }
    },
    removeVehicles: (state, action) => {
      return {
        ...state,
        premium: {
          ...state.premium,
          vehicles: state?.premium?.vehicles.filter((v) => v.rid !== action?.payload),
        },
      }
    },
    AddItemToVehicleAction: (state, action) => {
      return {
        ...state,
        premium: {
          ...state.premium,
          vehicles: action?.payload,
        },
      }
    },
    clearAndAddNewItemRisk: (state) => {
      return {
        ...state,
        master: state.allRisks.length === 1 ? state.coverPeriod : state.master,
        isRiskFormValid: false,
        step: 0,
        payment: {},
        clientId: 0,
        fleet: true,
        disableRiskDate: true,
        riskInfo: {},
        coverPeriod: {},
        coverInfo: {},
        policeHolder: {},
        calculatorResults: {},
        loading: false,
        error: false,
        message: false,
        success: false,
        triggerPH: false,
      }
    },
    showCalculator: (state, action) => {
      return { ...state, isCalculator: action?.payload }
    },
    manualCalAfterRemovingItem: (state, action) => {
      return { ...state, calculatorResults: action.payload }
    },
    validateRiskForm: (state, action) => {
      return { ...state, isRiskFormValid: action?.payload }
    },
    addNewRiskToItems: (state, action) => {
      return { ...state, allRisks: action.payload }
    },
    enableNextEvent: (state, action) => {
      return { ...state, triggerPH: action?.payload }
    },
    changeStep: (state, action) => {
      return { ...state, step: action.payload }
    },
    storeProduct: (state, action) => {
      return { ...state, productCode: action.payload }
    },
    persistRiskInfo: (state, action) => {
      return { ...state, riskInfo: action.payload }
    },
    persistCoverInfo: (state, action) => {
      return { ...state, coverInfo: action.payload }
    },
    persistPolicyHolderInfo: (state, action) => {
      return { ...state, policeHolder: action?.payload }
    },
    perisistCoverPeriod: (state, action) => {
      return { ...state, coverPeriod: action.payload }
    },
    paymentPersist: (state, action) => {
      return { ...state, payment: action.payload }
    },
    removeRiskItem: (state, action) => {
      const plateNoToRemove = action.payload
      state.allRisks = state.allRisks.filter((risk) => risk.plateNo !== plateNoToRemove)
      state.currentRisk = state.allRisks.length > 0 ? state.allRisks[state.allRisks.length - 1] : []
    },
  },
  extraReducers: (bP) => {
    bP.addCase(kycSubmitionAction.pending, (state) => {
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      }
    })
      .addCase(kycSubmitionAction.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          clientId: action?.payload?.data?.customerId,
          message: action?.payload?.message,
        }
      })
      .addCase(kycSubmitionAction.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(submitMotoQuotationAction.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(submitMotoQuotationAction.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          premium: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(submitMotoQuotationAction.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(getMotoPrivateInsuranceContract.pending, (state) => {
        return {
          ...state,
          loading: true,
          dwnldContract: false,
          success: false,
          error: false,
        }
      })
      .addCase(getMotoPrivateInsuranceContract.fulfilled, (state, action) => {
        return {
          ...state,
          dwnldContract: true,
          loading: true,
          success: true,
          error: false,
          contract: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(getMotoPrivateInsuranceContract.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          dwnldContract: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(getInsuranceContract.pending, (state) => {
        return {
          ...state,
          loading: true,
          dwnldContract: false,
          success: false,
          error: false,
        }
      })
      .addCase(getInsuranceContract.fulfilled, (state, action) => {
        return {
          ...state,
          dwnldContract: true,
          loading: false,
          success: true,
          error: false,
          contract: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(getInsuranceContract.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          dwnldContract: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(fireDomesticQuotation.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(fireDomesticQuotation.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          premium: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(fireDomesticQuotation.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(getProvince.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getProvince.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          provinces: action.payload || [],
          message: '',
        }
      })
      .addCase(getProvince.rejected, (state) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: 'Provice Rejected',
        }
      })
      .addCase(getDistrict.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getDistrict.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          districts: action.payload || [],
          message: 'Success',
        }
      })
      .addCase(getDistrict.rejected, (state) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: 'District Recject',
        }
      })
      .addCase(getSector.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getSector.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          sectors: action.payload || [],
          message: 'Success',
        }
      })
      .addCase(getSector.rejected, (state) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: 'getSector Rejected',
        }
      })
      .addCase(getCell.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getCell.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          cells: action.payload || [],
          message: 'Success',
        }
      })
      .addCase(getCell.rejected, (state) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: 'cell Rejected',
        }
      })
      .addCase(getVillages.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getVillages.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          villages: action.payload || [],
          message: 'Success',
        }
      })
      .addCase(getVillages.rejected, (state) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: 'Rejected',
        }
      })
      .addCase(getUpdatedPremiumCalculation.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getUpdatedPremiumCalculation.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          calculatorResults: action.payload?.data,
          message: action.payload.message,
        }
      })
      .addCase(getUpdatedPremiumCalculation.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
      .addCase(makePayment.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
          refleshPayment: true,
        }
      })
      .addCase(makePayment.fulfilled, (state) => {
        // let pm = action?.payload?.data?.paymentStatus
        return {
          ...state,
          loading: true,
          success: true,
          error: false,
          message:
            'Payment Request Is Sent if No Popup Check on the Pending Approval by Dialing *182*7*1*PIN#',
        }
      })
      .addCase(makePayment.rejected, (state) => {
        return {
          ...state,
          loading: false,
          success: false,
          refleshPayment: false,
          error: true,
          message: 'payment rejected',
        }
      })
      .addCase(getQuotationNoInfo.pending, (state) => {
        return {
          ...state,
          loading: true,
          success: false,
          error: false,
        }
      })
      .addCase(getQuotationNoInfo.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          success: true,
          error: false,
          premium: action.payload?.data,
          message: action.payload?.message,
        }
      })
      .addCase(getQuotationNoInfo.rejected, (state, action) => {
        return {
          ...state,
          loading: false,
          success: false,
          error: true,
          message: action.payload?.message,
        }
      })
  },
})
export const {
  storeProduct,
  changeStep,
  validateRiskForm,
  riskToSubmitAction,
  enableNextEvent,
  persistRiskInfo,
  persistPolicyHolderInfo,
  paymentPersist,
  addNewRiskToItems,
  persistCoverInfo,
  updateNidaState,
  UPDATE_ALL_RISKS,
  perisistCoverPeriod,
  newPremiumReset,
  manualCalAfterRemovingItem,
  updateSeachedQuotationAction,
  showCalculator,
  removeRiskItem,
  webSocketContractUpdates,
  AddItemToVehicleAction,
  removeVehicles,
  webSocketPaymentStatusUpdate,
  clearAndAddNewItemRisk,
} = BuyNewInsuranceInfo.actions
export default BuyNewInsuranceInfo.reducer
