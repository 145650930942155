import { Loader, MessageSquare, Send, X } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'

const ChatScope = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [messages, setMessages] = useState([
    {
      id: '1',
      content: "Hello! I'm your BK Insurance Assistant. How can I help you today?",
      sender: 'assistant',
    },
  ])
  const [inputMessage, setInputMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const messagesEndRef = useRef(null)

  const responses = {
    greetings: {
      patterns: [
        'hello',
        'hi',
        'hey',
        'greetings',
        'good morning',
        'good afternoon',
        'good evening',
      ],
      response: 'Hello! How can I assist you with our insurance services today?',
    },
    about: {
      patterns: ['about', 'company', 'who are you', 'what is bk', 'tell me about'],
      response:
        'BK General Insurance (BKGI) is a leading provider of non-life insurance products, offering motor, Agriculture, Transport, travel, and more.',
    },
    products: {
      patterns: ['products', 'insurance types', 'what insurance', 'services', 'offerings'],
      response:
        'We offer several insurance products:\n• Motor Insurance\n• Agriculture Insurance\n• Fire and Allied Perils Insurance\n• Travel Insurance\n• Transport Insurance\n• Accident Insurance',
    },
    motorInsurance: {
      patterns: ['motor', 'car', 'vehicle', 'automobile', 'bike', 'motorcycle'],
      response:
        'Our Motor Insurance provides comprehensive coverage for your vehicle. We offer:\n• Third Party Insurance\n• Comprehensive Coverage\n• Additional benefits like roadside assistance\nWould you like to know more about any specific motor insurance plan?',
    },
    agriculture: {
      patterns: ['agriculture', 'farm', 'crop', 'farming', 'livestock'],
      response:
        'Our Agriculture Insurance protects your farming investments. We cover:\n• Crop Insurance\n• Livestock Insurance\n• Farm Equipment Protection\nWhat specific aspect would you like to know more about?',
    },
    fire: {
      patterns: ['fire', 'property', 'home', 'house', 'building'],
      response:
        'Our Fire and Allied Perils Insurance covers:\n• Residential Properties\n• Commercial Buildings\n• Property Contents\n• Natural Disaster Protection\nWould you like more details about coverage options?',
    },
    travel: {
      patterns: ['travel', 'trip', 'journey', 'vacation', 'holiday'],
      response:
        'Our Travel Insurance includes:\n• Medical Emergency Coverage\n• Trip Cancellation Protection\n• Lost Luggage Compensation\n• 24/7 Emergency Assistance\nAre you interested in domestic or international travel insurance?',
    },
    claims: {
      patterns: ['claim', 'compensation', 'accident', 'damage', 'loss'],
      response:
        'To file a claim:\n1. Report the incident immediately\n2. Document the damage/loss\n3. Fill out our claim form\n4. Submit required documents\nWould you like me to guide you through the claims process?',
    },
    contact: {
      patterns: ['contact', 'phone', 'email', 'office', 'location', 'branch'],
      response:
        'You can reach us through:\n• Phone: [Your Contact Number]\n• Email: [Your Email]\n• Visit our branches\nWould you like the address of our nearest branch?',
    },
    contact: {
      patterns: ['thank you', 'thanks', 'appreciate', 'fine'],
      response: 'Thank You!! For choosing Bk Insurance',
    },
  }

  const findResponse = (message) => {
    const lowercaseMessage = message.toLowerCase()

    for (const [category, data] of Object.entries(responses)) {
      if (data.patterns.some((pattern) => lowercaseMessage.includes(pattern))) {
        return data.response
      }
    }

    return "I understand you're asking about our insurance services. Could you please be more specific about what you'd like to know? I can help you with information about our products, claims process, or general inquiries."
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!inputMessage.trim()) return

    const newUserMessage = {
      id: Date.now().toString(),
      content: inputMessage,
      sender: 'user',
    }

    setMessages((prev) => [...prev, newUserMessage])
    setInputMessage('')
    setIsLoading(true)

    setTimeout(() => {
      const botResponse = {
        id: (Date.now() + 1).toString(),
        content: findResponse(inputMessage),
        sender: 'assistant',
      }

      setMessages((prev) => [...prev, botResponse])
      setIsLoading(false)
    }, 500)
  }

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-center w-14 h-14 rounded-full bg-[#001f4d] hover:bg-[#002b6b] transition-colors shadow-lg"
        style={{ boxShadow: '0 4px 12px rgba(0, 31, 77, 0.2)' }}
      >
        {isOpen ? (
          <X className="w-6 h-6 text-white" />
        ) : (
          <MessageSquare className="w-6 h-6 text-white" />
        )}
      </button>

      {isOpen && (
        <div
          className="absolute bottom-16 right-0 w-96 bg-white rounded-lg shadow-xl flex flex-col overflow-hidden"
          style={{ height: '520px', boxShadow: '0 4px 24px rgba(0, 31, 77, 0.15)' }}
        >
          <div className="bg-[#001f4d] p-4 flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="flex-shrink-0 w-10 h-10 bg-white/10 rounded-full flex items-center justify-center">
                <MessageSquare className="w-6 h-6 text-white" />
              </div>
              <div>
                <h3 className="text-white font-semibold text-lg">BK Insurance Assistant</h3>
                <div className="flex items-center space-x-2">
                  <span className="w-2 h-2 bg-green-400 rounded-full"></span>
                  <p className="text-blue-200 text-sm">Online</p>
                </div>
              </div>
            </div>
            <button
              onClick={() => setIsOpen(false)}
              className="text-white/80 hover:text-white transition-colors p-1"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50">
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}
              >
                <div
                  className={`max-w-[80%] p-3 rounded-lg ${
                    msg.sender === 'user'
                      ? 'bg-[#001f4d] text-white rounded-br-none'
                      : 'bg-white text-gray-800 rounded-bl-none shadow-sm border border-gray-100'
                  }`}
                >
                  {msg.content.split('\n').map((line, i) => (
                    <p key={i} className={i > 0 ? 'mt-1' : ''}>
                      {line}
                    </p>
                  ))}
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex items-center space-x-2 text-gray-500 bg-white p-3 rounded-lg shadow-sm border border-gray-100 w-fit">
                <Loader className="w-4 h-4 animate-spin" />
                <span className="text-sm">Typing...</span>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <form onSubmit={handleSubmit} className="p-4 bg-white border-t border-gray-100">
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Ask me about our insurance products..."
                className="flex-1 p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#001f4d]/20 focus:border-[#001f4d]"
                disabled={isLoading}
              />
              <button
                type="submit"
                disabled={isLoading || !inputMessage.trim()}
                className="p-3 bg-[#001f4d] text-white rounded-lg hover:bg-[#002b6b] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <Send className="w-5 h-5" />
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default ChatScope
