import React, { useState } from 'react'
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import ActionProvider from 'src/ActionProvider'
import config from 'src/config'
import MessageParser from 'src/MessageParser'
import CloseIcon from 'src/views/pages/login/icons/Close'

const ChatAI = () => {
  const [isChatOpen, setIsChatOpen] = useState(false)

  const toggleChat = () => {
    setIsChatOpen((prev) => !prev)
  }

  return (
    <div>
      {/* Toggle Button */}
      <button
        onClick={toggleChat}
        style={{
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          backgroundColor: '#001f4d',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '55px',
          height: '55px',
          cursor: 'pointer',
          fontSize: '18px',
          textAlign: 'center',
          lineHeight: '55px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          zIndex: 1000,
        }}
        title={isChatOpen ? 'Close Chat' : 'Open Chat'}
      >
        {isChatOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="white"
            width="24px"
            height="24px"
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          >
            <path d="M19.071 4.929a1 1 0 00-1.414 0L12 10.586 6.343 4.93a1 1 0 00-1.415 1.415L10.586 12l-5.658 5.657a1 1 0 001.415 1.415L12 13.414l5.657 5.658a1 1 0 001.415-1.415L13.414 12l5.657-5.657a1 1 0 000-1.414z" />
          </svg>
        ) : (
          '💬'
        )}
      </button>

      {/* Chatbot Window */}
      {isChatOpen && (
        <div
          style={{
            position: 'fixed',
            bottom: '75px',
            right: '10px',
            width: '90%',
            maxWidth: '300px',
            height: '80%',
            maxHeight: '580px',
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #ccc',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            backgroundColor: '#f9f9f9',
            zIndex: 1000,
            ...(window.innerWidth <= 480 && {
              maxWidth: '100%',
              height: '90%',
            }),
          }}
        >
          {/* Chatbot Header */}
          <div
            style={{
              backgroundColor: '#001f4d',
              color: 'white',
              padding: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h4 style={{ margin: 0 }}>BK General Insurance</h4>
            <button
              onClick={toggleChat}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <CloseIcon />
            </button>
          </div>

          {/* Chatbot Content */}
          <div
            style={{
              flex: 1,
              overflowY: 'auto',
              padding: '10px',
            }}
          >
            <Chatbot
              config={{
                ...config,
                customComponents: {
                  ...config.customComponents,
                  header: () => null,
                },
              }}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ChatAI
