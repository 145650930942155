import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilUser } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { jsonData } from 'src/backend/utility'
import { forgotPassword } from 'src/actions/login/login-action'

//import { useNavigate } from 'react-router-dom'
import Loading from 'src/components/Loanding'

import { NotificationContainer } from 'react-notifications'

const ForgetPassword = () => {
  const [validated, setValidated] = useState(false)
  // const logoNegative = require('../../../assets/brand/logo-no-bg.png')
  const dispatch = useDispatch()

  const {
    userMngmt: { loading },
  } = useSelector((state) => state.app)

  //const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
    } else {
      let data = jsonData(form)
      dispatch(forgotPassword(data))
    }
    setValidated(true)
  }

  return (
    <div className="bg-login min-vh-100 d-flex flex-row align-items-center">
      <CContainer fluid>
        <CRow className="justify-content-center">
          <CCol md={6}>
            <CCardGroup>
              <CCard className="p-2">
                <CCardBody>
                  <CForm noValidate validated={validated} onSubmit={handleSubmit} method="POST">
                    <h2>Forget password/pin</h2>
                    <p className="text-medium-emphasis">Username/Email/phone</p>

                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Username/Email/ Phone"
                        name="username"
                        autoComplete="Phone"
                        required
                      />
                      <CFormFeedback invalid>Username/Password/Phone is Required</CFormFeedback>
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" type="submit" disabled={loading} className="px-4">
                          {loading ? <Loading size="sm" /> : 'Forget'}
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <Link to="/">
                          <CButton
                            color="success"
                            className="px-2 btn "
                            variant="outline"
                            type="button"
                          >
                            login Again
                          </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
        <NotificationContainer />
      </CContainer>
    </div>
  )
}
export default ForgetPassword
