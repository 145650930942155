import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import bkAppReducer from './root-reducer'
import { persistStore } from 'redux-persist'
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist/es/constants'

const isDevelopment = process.env.REACT_APP_ENV === 'development'

const store = configureStore({
  reducer: {
    app: bkAppReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(isDevelopment ? logger : [logger]),
})

const persistor = persistStore(store)

export { store, persistor }
