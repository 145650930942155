import { createAsyncThunk } from '@reduxjs/toolkit'

import { NotificationManager } from 'react-notifications'

import apiCall from 'src/backend/http'
export const createNewBeneficialOwnerAction = createAsyncThunk(
  'insurance/uob/addNewUob',
  async (requestData, toolkit) => {
    try {
      const { data } = await apiCall.post('insurance/uob/addNewUob', requestData)
      return data
    } catch (error) {
      return toolkit.rejectWithValue(error.response.data)
    }
  },
)

export const GetBeneficialOwnerReport = createAsyncThunk(
  'insurance/uob/beneficial-companies',
  async (req, tool) => {
    try {
      const { to, from } = req
      const { data } = await apiCall.get(
        `insurance/uob/beneficial-companies?startDate=${from}&endDate=${to}`,
      )
      return data
    } catch (error) {
      return tool.rejectWithValue(error.response.data)
    }
  },
)
export const GetQuotationReport = createAsyncThunk(
  'insurance/bk-tech/quotation/report',
  async (req, tool) => {
    try {
      const { to, from } = req
      const { data } = await apiCall.get(
        `insurance/bk-tech/quotation/report?startDate=${from}&endDate=${to}`,
      )
      return data
    } catch (error) {
      return tool.rejectWithValue(error.response.data)
    }
  },
)
export const repushEbmThatHasError = createAsyncThunk('insurance/ebm/repush', async (req, tool) => {
  try {
    const { data, status } = await apiCall.post(`insurance/ebm/repush`, req)
    if (status === 200) {
      NotificationManager.success(data?.message)
    } else {
      NotificationManager.warning(`Warning/Error Found:${data?.message}`)
    }
    return data
  } catch (error) {
    return tool.rejectWithValue(error.response.data)
  }
})
export const findGeneratedEbmInvoice = createAsyncThunk(
  'insurance/ebm/invoice',
  async (docNo, tool) => {
    try {
      const { data, status } = await apiCall.get(`insurance/ebm/invoice?docNo=${docNo}`)
      if (status === 200) {
        NotificationManager.success(data?.message)
      } else {
        NotificationManager.warning(`Warning/Error Found:${data?.message}`)
      }
      return data
    } catch (error) {
      const { code, message } = error.response.data

      NotificationManager.error(`Cdde:${code} ,Error: ${message}`)
      return tool.rejectWithValue(error.response.data)
    }
  },
)

export const getAllEbmData = createAsyncThunk('insurance/ebm/invoices', async (req, tool) => {
  try {
    ///ebm/invoices?from=2024-01-30&to=2024-11-26
    const { to, from } = req
    const { data } = await apiCall.get(`insurance/ebm/invoices?from=${from}&to=${to}`)
    return data
  } catch (error) {
    return tool.rejectWithValue(error.response.data)
  }
})
export const GetNidaUserInfo = createAsyncThunk(
  'nida/customer-info',
  async (nationalId, { rejectWithValue }) => {
    try {
      const { data } = await apiCall.get(`nida/customer-info?documentNo=${nationalId}`)

      return data
    } catch (e) {
      return rejectWithValue(e.response.data)
    }
  },
)
