import React from 'react'

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="#014D99"
      viewBox="0 0 256 256"
    >
      <path d="M208.49,191.51a12,12,0,0,1-17,0L128,128l-63.51,63.51a12,12,0,0,1-17-17L111,111,47.49,47.49a12,12,0,0,1,17-17L128,97l63.51-63.51a12,12,0,0,1,17,17L145,111l63.51,63.51A12,12,0,0,1,208.49,191.51Z" />
    </svg>
  )
}

export default CloseIcon
