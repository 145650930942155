import React, { Suspense } from 'react'
//clearimport { useSelector } from 'react-redux'
import { HashRouter, Route, Routes } from 'react-router-dom'
import ChatScope from './components/ChatScope/ChatScope'
import './scss/style.scss'
import ForgetPassword from './views/pages/forgetPassword/forgetPassword'
import ChatAI from './components/ChatAI/ChatAI'
//import ChatAI from './components/ChatAI/ChatAI'
// import Popup from './components/Popup'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Home = React.lazy(() => import('./views/pages/Home/Home'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Quatation = React.lazy(() => import('./layout/BkQuotationLayout'))
const MotoContracts = React.lazy(() => import('./views/contract_template/motoTemplate'))
const MotoCertificate = React.lazy(() => import('./views/certificate/MotoCertificate'))
const Story = React.lazy(() => import('./views/pages/Home/Story/Story'))
const Leadership = React.lazy(() => import('./views/pages/Home/Leadership/Leadership'))
const Subsidiaries = React.lazy(() => import('./views/pages/Home/Subsidiaries/Subsidiaries'))
const BkgiTariff = React.lazy(() => import('./views/pages/Home/BkgiTarrif/BkgiTariff'))
const BkgiReports = React.lazy(() => import('./views/pages/Home/BkgiReports/BkgiReports'))
const RenewPolicy = React.lazy(() => import('./views/pages/Home/RenewProcess/RenewProcess'))
const MotorInsurance = React.lazy(() => import('./views/pages/Home/MotorInsurance/MotorInsurance'))
const FireInsurance = React.lazy(() => import('./views/pages/Home/FireInsurance/FireInsurance'))
const AccidentInsurance = React.lazy(() =>
  import('./views/pages/Home/AccidentInsurance/AccidentInsurance'),
)
const EbmInvoices = React.lazy(() => import('./components/ebmInvoices'))
const TransportInsurance = React.lazy(() =>
  import('./views/pages/Home/TransportInsurance/TransportInsurance'),
)
const TravelInsurance = React.lazy(() =>
  import('./views/pages/Home/TravelInsurance/TravelInsurance'),
)

const AgricultureInsurance = React.lazy(() =>
  import('./views/pages/Home/AgricultureInsurance/AgricultureInsurance'),
)

const AviationInsurance = React.lazy(() =>
  import('./views/pages/Home/AviationInsurance/AviationInsurance'),
)
const MoneyInsurance = React.lazy(() => import('./views/pages/Home/MoneyInsurance/MoneyInsurance'))
const HomeInsurance = React.lazy(() => import('./views/pages/Home/HomeInsurance/HomeInsurance'))
const ClaimProcess = React.lazy(() => import('./views/pages/Home/ClaimProcess/ClaimProcess'))
const HelpCenter = React.lazy(() => import('./views/pages/Home/HelpCenter/HelpCenter'))
const NidaPreview = React.lazy(() => import('./views/nida/nida-preview'))
const App = () => {
  // const { token } = useSelector((state) => state.app?.userMngmt)
  return (
    <>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="BK-GI" element={<Home />} />
            <Route exact path="/nida" name="BK-GI" element={<NidaPreview />} />
            <Route exact path="/login" name="BK-GI Quotation" element={<Login />} />
            <Route exact path="/moto-contract" name="Moto Contract" element={<MotoContracts />} />
            <Route
              exact
              path="/motor-certificate"
              name="BK-GI Certificate"
              element={<MotoCertificate />}
            />
            <Route exact path="/register" name="BK-GI REGISTER" element={<Register />} />
            <Route exact path="/forget-pwd" name="Forget Password" element={<ForgetPassword />} />
            <Route exact path="/self-service" name="BK-GI Quotation" element={<Quatation />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />

            <Route exact path="/about/story" name="BKGI Story" element={<Story />} />
            <Route exact path="/about/leadership" name="BKGI Leadership" element={<Leadership />} />
            <Route
              exact
              path="/about/companies"
              name="BKGI & Companies"
              element={<Subsidiaries />}
            />
            <Route exact path="/about/tariffs" name="BKGI Tariffs" element={<BkgiTariff />} />
            <Route exact path="/about/reports" name="BKGI Reports" element={<BkgiReports />} />
            <Route exact path="/renew" name="Renew Process" element={<RenewPolicy />} />
            <Route
              exact
              path="/product/motor"
              name="Motor Insurance"
              element={<MotorInsurance />}
            />
            <Route exact path="/product/fire" name="Fire Insurance" element={<FireInsurance />} />
            <Route
              exact
              path="/product/transport"
              name="Transport Insurance"
              element={<TransportInsurance />}
            />
            <Route
              exact
              path="/product/travel"
              name="Travel Insurance"
              element={<TravelInsurance />}
            />
            <Route
              exact
              path="/product/accident"
              name="Accident Insurance"
              element={<AccidentInsurance />}
            />
            <Route
              exact
              path="/product/agriculture"
              name="Agriculture Insurance"
              element={<AgricultureInsurance />}
            />
            <Route
              exact
              path="/product/motor"
              name="Motor Insurance"
              element={<MotorInsurance />}
            />
            <Route exact path="/product/fire" name="Fire Insurance" element={<FireInsurance />} />
            <Route
              exact
              path="/product/transport"
              name="Transport Insurance"
              element={<TransportInsurance />}
            />
            <Route
              exact
              path="/product/travel"
              name="Travel Insurance"
              element={<TravelInsurance />}
            />
            <Route
              exact
              path="/product/accident"
              name="Accident Insurance"
              element={<AccidentInsurance />}
            />
            <Route
              exact
              path="/product/agriculture"
              name="Agriculture Insurance"
              element={<AgricultureInsurance />}
            />
            <Route
              exact
              path="/product/aviation"
              name="Aviation Insurance"
              element={<AviationInsurance />}
            />
            <Route
              exact
              path="/product/money"
              name="Money Insurance"
              element={<MoneyInsurance />}
            />
            <Route exact path="/invoice" name="Ebm Invoice" element={<EbmInvoices />} />
            <Route
              exact
              path="/product/aviation"
              name="Aviation Insurance"
              element={<AviationInsurance />}
            />
            <Route
              exact
              path="/product/money"
              name="Money Insurance"
              element={<MoneyInsurance />}
            />
            <Route exact path="/product/home" name="Home Insurance" element={<HomeInsurance />} />
            <Route exact path="/service/claim" name="Claim Process" element={<ClaimProcess />} />
            <Route exact path="/support/help" name="Help Center" element={<HelpCenter />} />
            <Route path="*" element={<DefaultLayout />} name="admin" />
            {/* <Route path="*" element={token != null ? <DefaultLayout /> : <DefaultLayout />} />*/}
          </Routes>
        </Suspense>
        {/*<ChatAI />*/}
        <ChatScope />
      </HashRouter>
    </>
  )
}

export default App
